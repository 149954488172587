var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-left text-black"
  }, [_vm.statusData ? _c('span', {
    class: _vm.statusData.class
  }, [_vm._v(" " + _vm._s(_vm.statusData.label) + " ")]) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }