<template>
  <div class="text-left">
    <span v-if="clientData">
      {{ clientData.label }}
    </span>
    <span v-else>
      {{ client }}
    </span>
  </div>
</template>

<script>
export default {
  name: "client",
  props: {
    client: {
      type: Number,
      required: true,
    },
  },
  computed: {
    clientData() {
      const clients = {
        1: { label: "Apple" },
        2: { label: "Android" },
        3: { label: "Windows" },
        7: { label: "Firefox Browser" },
        67: { label: "E-perpus" },
        82: { label: "Gramedia.com" },
      };
      return clients[this.client] || null;
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
