<template>
  <div class="text-left">
    <span v-if="gatewayData">
      {{ gatewayData.label }}
    </span>
    <span v-else>
      {{ gateway }}
    </span>
  </div>
</template>

<script>
export default {
  name: "gateway",
  props: {
    gateway: {
      type: Number,
      required: true,
    },
  },
  computed: {
    gatewayData() {
      const gateways = {
        1: { label: "iTunes" },
        15: { label: "Google Wallet" },
        12: { label: "CC" },
        23: { label: "Gramedia" },
        24: { label: "scoopwallet" },
        28: { label: "VA Permata" },
        29: { label: "VA Bca" },
        30: { label: "VA Mandiri" },
        31: { label: "VA BNI" },
        38: { label: "ShoppePay" },
        33: { label: "Linkaja" },
        32: { label: "Gopay" },
        35: { label: "CIMB Clicks" },
        36: { label: "CIMB go Mobile" },
        37: { label: "CIMB RekPon" },
        3: { label: "FREE Purchase" },
        39: {label:"Coupon"},
        50: { label: "Qris" },
      };
      return gateways[this.gateway] || null;
    },
  },
};
</script>

<style scoped>
.text-center {
  text-align: center;
}
</style>
