var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-left"
  }, [_vm.clientData ? _c('span', [_vm._v(" " + _vm._s(_vm.clientData.label) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.client) + " ")])]);
}
var staticRenderFns = []

export { render, staticRenderFns }