<template>
  <div class="text-left text-black">
    <span v-if="statusData" :class="statusData.class">
      {{ statusData.label }}
    </span>
  </div>
</template>

<script>
export default {
  name: "payment",
  props: {
    payment: {
      value: [String,Number,Boolean],
      required: true,
    },
  },
  computed: {
    statusData() {
      const statuses = {
        90000: { class: "bg-green-100 text-green-800 py-1 rounded-lg text-sm px-2 font-normal", label: "Paid" },
        50000: { class: "bg-red-100 text-red-800 py-1 rounded-lg text-sm px-2 font-normal", label: "Cancel" },
        50001: { class: "bg-red-100 text-red-800 py-1 rounded-lg text-sm px-2 font-normal", label: "Error" },
        50002: { class: "bg-red-100 text-red-800 py-1 rounded-lg text-sm px-2 font-normal", label: "Delivery Error" },
        50004: { class: "bg-gray-100 text-gray-900 py-1 rounded-lg text-sm px-2 font-normal", label: "Expire" },
        10001: { class: "bg-blue-100 text-blue-800 py-1 rounded-lg text-sm px-2 font-normal", label: "New" },
        20001: { class: "bg-yellow-100 text-yellow-800 py-1 rounded-lg text-sm px-2 font-normal", label: "Waiting Payment" },
        20002: { class: "bg-yellow-100 text-yellow-800 py-1 rounded-lg text-sm px-2 font-normal", label: "Proses" },
        40003: { class: "bg-purple-100 text-purple-800 py-1 rounded-lg text-sm px-2 font-normal", label: "Refund" },
        50003: { class: "bg-red-100 text-red-800 py-1 rounded-lg text-sm px-2 font-normal", label: "Denied" },
      };
      return statuses[this.payment] || null;
    },
  },
};
</script>

<style scoped>
.icon {
  width: 17px;
}
.badge {
  color: black;
}
</style>